/* Config */
$color-bg: #1e1e1e;
$color-particle: rgba(255, 255, 255, 0.356);
$spacing: 3060px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max) {
  $val: 0px 0px $color-particle;
  @for $i from 1 through $max {
    $val: #{$val}, random($spacing) + px random($spacing) + px $color-particle;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}

.page-bg,
.animation-wrapper {
  top: 0;
  left: 0;
  width: 100%;
}

.page-bg {
  background: $color-bg;
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
  z-index: -2;
}

.particle,
.particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}

.particle-1 {
  animation: animParticle $time-1 linear infinite;
  @include particles(400);
  height: 2px;
  width: 2px;
}

.particle-1:after {
  @include particles(600);
  height: 2px;
  width: 2px;
}

.particle-2 {
  animation: animParticle $time-2 linear infinite;
  @include particles(600);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles(300);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles(400);
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite;
  @include particles(700);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles(400);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from {
    transform: translateY(4px);
  }
  to {
    transform: translateY($spacing * -1);
  }
}
