@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&family=Ubuntu:wght@700&display=swap");

.projectheading {
  font-family: "Ubuntu", sans-serif;
  font-size: 3rem;
  color: #622aff;
  text-align: center;
  margin-top: 5vh;
}
.projectCard {
  background-color: #121212;
  box-shadow: 0px 0px 4px 8px rgb(48, 49, 49);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 40px;
  height: 90%;
}
.projectimg {
  width: 100%;
  margin-bottom: 1em;
  border-radius: 10px;
}
.projectheading {
  width: 12rem;
  margin-bottom: 1em;
}

.ProjectSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projectInfo {
  color: white;
  text-align: justify;
  font-size: 17px;
}

figcaption {
  color: white;
}

.projectrow {
  margin-bottom: 4em;
}
.projectCarousel {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: -10%;
}
.projectname {
  color: #9370db;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

@media (max-width: 767px) {
  /* .projectCarousel {
    margin-left: -15%;
  } */
  .projectrow {
    margin-bottom: 2em;
  }
  .projectname {
    color: #9370db;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .projectheading {
    font-size: 2rem;
    width: 8rem;
  }

  .ProjectSection {
    padding: 1rem;
  }
  .projectInfo {
    font-size: 12px;
  }
}
.tech {
  background: rgba(74, 126, 197, 0.4);
  border: 2px solid rgb(117, 165, 233) !important;
  color: rgb(117, 165, 233) !important;
  padding: 3px;
  border-radius: 20px;
  width: 4.6em;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
}

.techcontainer {
  text-align: center;
  align-items: center;
  border: 1px solid white;
  display: flex;
}
