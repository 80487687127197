@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&family=Ubuntu:wght@700&display=swap");

.skillheading {
  font-family: "Ubuntu", sans-serif;
  font-size: 3rem;
  color: #622aff;
  width: 7.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
}
.SkillSection {
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.skillCards {
  justify-content: center;
}

.skillimg {
  border-radius: 19px;
}
.Skill {
  background-color: #121212;
}
.skillCard {
  text-align: center;
  background-color: #121212;
  box-shadow: 0px 0px 4px 8px rgb(48, 49, 49);
  opacity: 0.8;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  border-radius: 20px;
}

.smallcard {
  width: 24em;
  height: 16em;
  margin-top: 1em;
}

.bigcard {
  width: 16em;
  height: 34.5em;
  margin-top: 1em;
}
.skillCard img {
  margin-bottom: 2em;
  width: 5em;
  box-shadow: 0px 0px 5px 3px rgb(251, 255, 255);
}
.skillcaption {
  color: white;
  margin-top: -20px;
}
@media (max-width: 1199.5px) {
  .skillCard img {
    margin-bottom: 1em;
    width: 50px;
    border-radius: 10px;
    box-shadow: none;
  }
  .smallcard {
    width: 18em;
    height: 11em;
    margin-top: 25px;
    padding-bottom: 5px;
    margin-bottom: 0px;
  }
  .bigcard {
    width: 12em;
    height: 23em;
    margin-top: 40px;
  }
  .skillcaption {
    margin-top: -5px;
  }
}

@media (max-width: 991.5px) {
  .skillCard img {
    margin-bottom: 1em;
    width: 40px;
    border-radius: 10px;
    box-shadow: none;
  }
  .smallcard {
    width: 15em;
    height: 11em;
    margin-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 0px;
  }
  .bigcard {
    width: 10em;
    height: 23em;
    margin-top: 25px;
  }
  .skillcaption {
    margin-top: -5px;
  }
}
@media screen and (min-width: 800px) {
  .bigcard {
    padding-top: 2em;
  }
}

@media (max-width: 767px) {
  .skillCard img {
    margin-bottom: 1em;
    width: 45px;
    border-radius: 10px;
  }
  .smallcard {
    width: 18em;
    height: 10em;
    margin-top: 5px;
    padding-bottom: 5px;
    margin-bottom: -10px;
  }
  .bigcard {
    width: 12em;
    height: 18em;
    margin-top: 30px;
    padding-top: -2em;
  }
  .skillcaption {
    margin-top: -12px;
  }
}
@media (max-width: 767px) {
  .skillheading {
    font-size: 2rem;
    width: 5rem;
  }
  .SkillSection {
    padding: 1rem;
  }
  .skillCard {
    box-shadow: 0px 0px 4px 3px rgb(52, 53, 53);
  }
}
