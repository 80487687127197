.ContactSection {
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.contactheading {
  font-family: "Ubuntu", sans-serif;
  font-size: 3rem;
  color: #622aff;
}

.contactForm {
  margin: auto;
  text-align: center;
  color: white;
  border-radius: 10px;
  background: #141519;
  box-shadow: 0px 0px 20px 5px #0ff;
  width: fit-content;
  padding: 1em;
  opacity: 0.99;
  margin-top: 2em;
}
.lightText {
  color: #b6b6b6;
  font-size: 18px;
}

input:-webkit-autofill {
  background-color: #22232a;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
  font-size: 15px;
  height: 45px;
  border: none;
  padding-left: 15px;
  width: -webkit-calc(100% - 15px);
  width: -moz-calc(100% - 15px);
  width: calc(100% - 15px);
  margin-bottom: 10px;
  color: #e0e1e4;
}

input[type="text"],
input[type="number"],
textarea {
  background-color: #22232a;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
  font-size: 15px;
  height: 45px;
  border: none;
  padding-left: 15px;
  width: -webkit-calc(100% - 15px);
  width: -moz-calc(100% - 15px);
  width: calc(100% - 15px);
  margin-bottom: 10px;
  color: #e0e1e4;
}
textarea {
  height: 65px;
  color: #e0e1e4;
  padding-top: 10px;
}
input[type="text"][placeholder] {
  color: #e0e1e4;
  font-size: 15px;
  font-weight: 500;
}

.btn {
  text-decoration: none;
  border: 1px solid #0ff;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  color: white;
}

.btn:hover {
  box-shadow: 1px 1px 13px 2px #5ff0d0;
  color: white;
}

.btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(146, 148, 248, 0.4),
    transparent
  );
  transition: all 650ms;
}

.btn:hover:before {
  left: 100%;
}
